import React from "react"
import { LinkText } from "@bankiru/ui-kit/components/Link/LinkText";
import Flexbox from "@bankiru/ui-kit/core/Flexbox";
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Text from "@bankiru/ui-kit/core/Text"
import TextList from "@bankiru/ui-kit/core/TextListLegacy"
import {
  ADDRESS,
  MAIL,
  PHONE_NUMBER,
  STATE_REGISTRATION,
  TARIFFS_SERVICES_PLATFORM,
} from "../../constants"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"
import { partners } from "./partners"

const { title, link } = STATE_REGISTRATION

export const getAccordionData = isMobile => [
  {
    title: "Партнеры",
    body: (
      <TextList
        gapSize="medium"
        textSize={isMobile ? "small" : "large"}
        bulletsType="circle-fill"
        data={{
          list: [
            {
              text:
                "Перечень третьих лиц, обеспечивающих по соглашению с Оператором платформы размещение информации о предложениях Финансовых организаций на совершение Финансовых сделок",
              comment: (
                <>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    ООО «Информационное агентство «Банки.ру» www.banki.ru
                  </Text>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    ООО «Банки.ру Искусственный интеллект»
                  </Text>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    ООО «ФРЕШ КРЕДИТ»
                  </Text>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    ООО «РИТЕЙЛ-ФИНАНС»
                  </Text>
                </>
              ),
            },
            {
              text:
                "Перечень банков, которым Оператором платформы поручено проведение Идентификации в целях присоединения к Правилам финансовой платформы",
              comment: (
                <>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    – АО «ТБанк», 127287, ул. 2-я Хуторская, дом 38А, стр. 26, г. Москва, ИНН 7710140679, ОГРН 1027739642281
                  </Text>
                  <Text size={isMobile ? "fs12" : "fs14"}>
                    – АО «АЛЬФА-БАНК», 107078, ул. Каланчевская, дом 27,  г. Москва, ИНН 7728168971, ОГРН 1027700067328
                  </Text>
                </>
              ),
            },
            {
              text:
                "Информация о расторжении договора об оказании услуг между Оператором платформы и Финансовой организацией",
              comment: (
                <Text size={isMobile ? "fs12" : "fs14"}>
                  Договоры не расторгались
                </Text>
              ),
            },
            {
              text:
                "Перечень Финансовых организаций - участников Финансовой платформы",
              comment: (
                <FlexboxGrid direction="vert" gap="xxs">
                  {partners.map(({ data, id }) => (
                    <Text key={id} size={isMobile ? "fs12" : "fs14"}>
                      {data}
                    </Text>
                  ))}
                </FlexboxGrid>
              ),
            },
          ],
        }}
      />
    ),
  },
  {
    title: "Специальный счет оператора платформы",
    body: (
      <TextList
        gapSize="medium"
        textSize={isMobile ? "small" : "large"}
        data={{
          list: [
            {
              text: (
                <Text weight="medium">
                  Реквизиты специального счета АО&nbsp;&laquo;Банки.ру
                  Маркетплейс&raquo;:
                </Text>
              ),
              comment: (
                <Text color="primary" size={isMobile ? "fs14" : "fs18"}>
                  <div>
                    счет &#8470;&nbsp;40701810387360000821&nbsp;в Росбанк филиал Москва АО&nbsp;&laquo;ТБанк&raquo; БИК 044525113&nbsp;к/с 30101810545374525113
                  </div>
                  <div>
                    счет &#8470;&nbsp;40701810387360000821&nbsp;в ПАО &laquo;РОСБАНК&raquo; БИК 044525256&nbsp;к/с 30101810000000000256 (действует до&nbsp;30.06.2025&nbsp;включительно)
                  </div>
                </Text>
              ),
            },
          ],
        }}
      />
    ),
  },
  {
    title: "Тарифы",
    body: (
      <LinkText href={TARIFFS_SERVICES_PLATFORM.link} skin="blue" target="_blank">
        Тарифы на услуги для финансовых организаций
      </LinkText>
    ),
  },
  {
    title: "Взаимодействие с Агентством по страхованию вкладов",
    body: (
      <TextList
        gapSize="medium"
        textSize={isMobile ? "small" : "large"}
        bulletsType="circle-fill"
        data={{
          list: [
            {
              text: (
                <LinkText
                  href="https://www.asv.org.ru/support?section=banki#chto-delat-vkladchiku-esli-on-ne-soglasen-s-razmerom-vozmeshcheniya-po-vkladam"
                  skin="blue"
                  target="_blank"
                >
                  Что делать, если вкладчик не согласен с размером возмещения по
                  вкладам?
                </LinkText>
              ),
            },
            {
              text: (
                <LinkText
                  href="https://www.asv.org.ru/support?section=banki#v-techenie-kakogo-vremeni-vkladchik-vprave-obratitsya-s-zayavleniem-o-vyplate-vozmeshcheniya-po-vkla"
                  skin="blue"
                  target="_blank"
                >
                  Что делать при наступлении страхового случая, указанного в п.
                  2 ч. 1 статьи 8 Федерального закона от 23.12.2003 г. №177-ФЗ
                </LinkText>
              ),
            },
          ],
        }}
      />
    ),
  },
  {
    title: "Конфликты интересов",
    body: "Конфликты не выявлены",
  },
  {
    title: "Технические сбои",
    body: "Технические сбои отсутствуют",
  },
  {
    title: "Оставить обращение",
    body: (
      <Flexbox flexDirection="column" gap={isMobile ? '8' : '12'}>
        <Text>Вы можете оставить свое обращение Финансовой платформе:</Text>
        <TextList
          gapSize={isMobile ? "small" : "medium"}
          textSize={isMobile ? "small" : "large"}
          bulletsType="circle-fill"
          data={{
            list: [
              {
                text: (
                        <Text>
                            Cообщением по электронной почте на адрес Финансовой платформы{' '}
                            <LinkText
                                href="mailto:client_support@banki.ru"
                                skin="blue"
                                target="_blank"
                            >
                                client_support@banki.ru
                            </LinkText>
                        </Text>
                ),
              },
              {
                text: 'по телефону 8 495 137 87 87'
              },
              {
                text: 'направив обращение на бумажном носителе по адресу 117638, г. Москва, вн. тер. г. муниципальный округ Зюзино, ул. Одесская, д. 2, ком. 5'
              },
            ],
          }}
        />
        <Text>
            Также{' '}
            <LinkText
                href="https://www.banki.ru/specials/other/Marketplejs/spcard/"
                skin="blue"
                target="_blank"
            >
                здесь
            </LinkText>
            {' '}вы найдете ответы на самые часто задаваемые вопросы
        </Text>
        <Text>
            Обращаем ваше внимание, что обращения рассматриваются в порядке и сроках, установленными Федеральным законом от 20.07.2020 N 211-ФЗ «О совершении финансовых сделок с использованием финансовой платформы»
        </Text>
      </Flexbox>
    ),
  },
  {
    title: "Архив документов",
    body: (
      <TextList
        gapSize="medium"
        textSize={isMobile ? "small" : "large"}
        data={{
          list: [
            {
              text: (
                <LinkText
                  href="https://static4.banki.ru/cfe/NSP_AO_CT_Ustav.pdf"
                  skin="blue"
                  target="_blank"
                >
                  Устав ред. с 30.11.2021 по 21.07.2023
                </LinkText>
              ),
            },
            {
              text: (
                  <LinkText
                      href="https://static2.banki.ru/mkp/rules_ofp_11.pdf"
                      skin="blue"
                      target="_blank"
                  >
                    Правила финансовой платформы, действовавшие с 24.10.2024 по 24.03.2025
                  </LinkText>
              ),
            },
            {
              text: (
                  <LinkText
                      href="https://static2.banki.ru/mkp/rules_ofp_10.pdf"
                      skin="blue"
                      target="_blank"
                  >
                    Правила финансовой платформы, действовавшие с 15.07.2024 по 23.10.2024
                  </LinkText>
              ),
            },
            {
              text: (
                  <LinkText
                      href="https://static4.banki.ru/cfe/rules_operator_finplatform_9.pdf"
                      skin="blue"
                      target="_blank"
                  >
                    Правила финансовой платформы, действовавшие с 10.01.2024 по 14.07.2024
                  </LinkText>
              ),
            },
            {
              text: (
                  <LinkText
                      href="https://static4.banki.ru/cfe/operator_rules_FP_v8.pdf"
                      skin="blue"
                      target="_blank"
                  >
                    Правила финансовой платформы, действовавшие с 27.11.2023 по 9.01.2024
                  </LinkText>
              ),
            },
            {
              text: (
                  <LinkText
                      href="https://static2.banki.ru/cfe/pravila_operatora_fin_platformy_v7.pdf"
                      skin="blue"
                      target="_blank"
                  >
                    Правила финансовой платформы, действовавшие с 19.09.2023 по 26.11.2023
                  </LinkText>
              ),
            },
            {
              text: (
                <LinkText
                  href="https://static4.banki.ru/mkp/pravila_operatora_fin_platformy_v6.pdf"
                  skin="blue"
                  target="_blank"
                >
                  Правила финансовой платформы, действовавшие с 20.05.2023 по 18.09.2023
                </LinkText>
              ),
            },
            {
              text: (
                <LinkText
                  href="https://static4.banki.ru/mkp/pravila_operatora_fin_platformy_v5.pdf"
                  skin="blue"
                  target="_blank"
                >
                  Правила финансовой платформы, действовавшие с 14.03.2023 по 19.05.2023
                </LinkText>
              ),
            },
            {
              text: (
                <LinkText
                  href="https://static2.banki.ru/mkp/legal_rules_digital_techs_v4.pdf"
                  skin="blue"
                  target="_blank"
                >
                  Правила финансовой платформы, действовавшие с 28.12.2022 по
                  13.03.2023
                </LinkText>
              ),
            },
            {
              text: (
                <LinkText
                  href="https://static4.banki.ru/mkp/legal_rules_digital_techs_prev.pdf"
                  skin="blue"
                  target="_blank"
                >
                  Правила финансовой платформы, действовавшие с 07.09.2022 по
                  27.12.2022
                </LinkText>
              ),
            },
          ],
        }}
      />
    ),
  },
  {
    title: "Контакты",
    body: (
      <TextList
        gapSize="medium"
        textSize={isMobile ? "small" : "large"}
        data={{
          list: [
            {
              text:
                "6 сентября 2022 года Акционерное общество «Банки.ру Маркетплейс» включено Банком России в реестр операторов финансовых платформ, в соответствии с частью 11 статьи 11 Федерального закона от 20 июля 2020 г. No 211-ФЗ «О совершении финансовых сделок с использованием финансовой платформы»",
            },
            {
              text: (
                <Text weight="medium">
                  Фирменное наименование Оператора платформы:
                </Text>
              ),
              comment: (
                <Text color="primary" size={isMobile ? "fs14" : "fs18"}>
                  <div>
                    Акционерное общество «Банки.ру Маркетплейс», ОГРН
                    1217700577819
                  </div>
                  <LinkText href={link} skin="blue" target="_blank">
                    {title}
                  </LinkText>
                </Text>
              ),
            },
            {
              text: (
                <Text weight="medium">
                  Местонахождение оператора платформы:
                </Text>
              ),
              comment: (
                <Text color="primary" size={isMobile ? "fs14" : "fs18"}>
                  {ADDRESS}
                </Text>
              ),
            },
            {
              text: <Text weight="medium">Контактная информация:</Text>,
              comment: (
                <FlexboxGrid
                  direction={isMobile ? "vert" : "row"}
                  gap={isMobile ? "zero" : "xxs"}
                >
                  <Text color="primary" size={isMobile ? "fs14" : "fs18"}>
                    Е-mail:{" "}
                    <LinkText href={`mailto:${MAIL}`} skin="blue">
                      {MAIL}
                    </LinkText>
                    ,
                  </Text>
                  <Text color="primary" size={isMobile ? "fs14" : "fs18"}>
                    телефон:{" "}
                    <LinkText href={`tel:${PHONE_NUMBER}`} skin="blue">
                      {formatPhoneNumber(PHONE_NUMBER)}
                    </LinkText>
                  </Text>
                </FlexboxGrid>
              ),
            },
          ],
        }}
      />
    ),
  },
]
